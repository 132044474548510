import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Loading from './Loading'

import { DateConverter } from '../utilities/DateConverter'

export default class CalendarEvents extends Component {
  render() {
    const {
      isAdmin,
      presence,
      loading,
      calendarEvents,
      categories,
      frame,
      totalEventCount,
      destroy,
      goToCalendars,
      previousWeek,
      nextWeek,
      weekStart,
    } = this.props
    const moreEvents = calendarEvents.length - totalEventCount
    if (loading) {
      return <Loading />
    }
    return (
      <div>
        <div className="row">
          <div className="col-4 pt-5">
            <button type="button" onClick={previousWeek} className="btn btn-sm btn-info">
              <span className="oi oi-arrow-circle-left" title="Calendar" aria-hidden="true" />
              &nbsp; Prev. Week
            </button>
          </div>
          <div className="col-4">
            <div>
              {calendarEvents.length === 0 ? (
                <div>
                  <h2 className="text-center text-muted mt-4">No Events</h2>
                  {totalEventCount > 0 && (
                    <p className="text-center text-muted">
                      In the week of {DateConverter.call(weekStart, true)}
                    </p>
                  )}
                </div>
              ) : (
                <div>
                  <h2 className="text-center text-muted mt-4">My Events</h2>
                  <p className="text-center text-muted">
                    Week of {DateConverter.call(weekStart, true)}
                  </p>
                </div>
              )}
            </div>
            <div>
              {isAdmin && (
                <div className="text-center mb-0">
                  {presence ? (
                    <>
                      <span>
                        <span className="text-success">{presence}</span>{' '}
                        <span className="muted">w/ token {frame.attributes.pushy_token}</span>
                      </span>
                      <div className="text-center">
                        {frame.attributes.activated_at
                          ? `Activated on ${DateConverter.MMDDYYY(
                              frame.attributes.activated_at
                            ).replace(/\./g, '-')}`
                          : 'Not activated'}
                      </div>
                      <div className="text-center">{frame.attributes.hardware_model}</div>
                    </>
                  ) : (
                    <span className="muted">Has not yet been turned on</span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-4 pt-5 text-right">
            <button type="button" onClick={nextWeek} className="btn btn-sm btn-info">
              Next Week &nbsp;{' '}
              <span className="oi oi-arrow-circle-right" title="Calendar" aria-hidden="true" />
            </button>
          </div>
        </div>
        {calendarEvents.length === 0 && (
          <div className="row">
            <div className="col-12">
              {totalEventCount > 0 ? (
                <p className="text-center mt-4">
                  {totalEventCount} event{totalEventCount !== 1 && 's'} in total{' '}
                  {totalEventCount === 1 ? 'has' : 'have'} been added to this Calendar. Send more by
                  inviting <b>{frame.attributes.name}@ourskylight.com</b> to an event.
                </p>
              ) : (
                <p className="text-center mt-4">
                  You can add Calendar Events to your Calendar by inviting{' '}
                  <b>{frame.attributes.name}@ourskylight.com</b> to an event.
                </p>
              )}
            </div>
          </div>
        )}
        {calendarEvents.map((event) => {
          const category = event.relationships.category.data ? categories.find((c) => c.id === event.relationships.category.data.id) : null
          return (
            <div key={event.id} className="row">
              <div className="col-12">
                <div
                  className="grocery-item-wrapper"
                  style={{
                    borderLeftWidth: 5,
                    borderLeftColor: category ? category.attributes.color : '#CDCDCD',
                    borderLeftStyle: 'solid',
                    paddingLeft: 20,
                  }}
                >
                  <div className="row">
                    <div className="col-10">
                      <h3 className="text-muted ellipsis-overflow">{event.attributes.summary}</h3>
                      {event.attributes.all_day ? (
                        <p className="text-sm">
                          all day on{' '}
                          <b>{DateConverter.call(event.attributes.starts_at, true, true)}</b>
                        </p>
                      ) : (
                        <p className="text-sm">
                          from <b>{DateConverter.time(event.attributes.starts_at)}</b> to{' '}
                          <b>{DateConverter.call(event.attributes.ends_at)}</b>
                        </p>
                      )}
                    </div>
                    <div className="col-2 text-right">
                      <button
                        type="button"
                        onClick={() => destroy(event)}
                        className="btn btn-sm btn-info"
                      >
                        <span className="oi oi-trash" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        {moreEvents > 0 && (
          <div className="row">
            <div className="col-12 mt-2">
              <p className="text-center text-muted">
                <b>
                  {moreEvents} more event{moreEvents !== 1 && 's'}
                </b>{' '}
                not in the next week...
              </p>
            </div>
          </div>
        )}
        <div className="row mt-5">
          <div className="col-12 text-center">
            <button type="button" onClick={goToCalendars} className="btn btn-success">
              Manage Synced Calendars
            </button>
          </div>
        </div>
      </div>
    )
  }
}

CalendarEvents.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  presence: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  frame: PropTypes.object,
  categories: PropTypes.array.isRequired,
  calendarEvents: PropTypes.array.isRequired,
  totalEventCount: PropTypes.number,
  destroy: PropTypes.func.isRequired,
  goToCalendars: PropTypes.func.isRequired,
  previousWeek: PropTypes.func.isRequired,
  nextWeek: PropTypes.func.isRequired,
  weekStart: PropTypes.object.isRequired,
}
